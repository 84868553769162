import React from 'react';
import {string} from 'prop-types';
import {Link} from '@reach/router';
import './breadcrumbs.css';

const Breadcrumbs = ({page}) => (
  <div className="breadcrumbs">
    <span>Home /</span>{' '}
    <Link to={`/${page.toLowerCase().replace(/ /g, '-')}`}>{page}</Link>
  </div>
);

Breadcrumbs.propTypes = {
  page: string.isRequired
};

export default Breadcrumbs;
