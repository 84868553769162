import React from 'react';
import {string, shape, object} from 'prop-types';
import fetchJsonp from 'fetch-jsonp';
import config from '../../config';
import PrismicLink from '../prismic-link';
import './instagram.css';

class Instagram extends React.Component {
  state = {
    photos: [
      {src: '/blank.jpg', id: '1', link: '#'},
      {src: '/blank.jpg', id: '2', link: '#'},
      {src: '/blank.jpg', id: '3', link: '#'},
      {src: '/blank.jpg', id: '4', link: '#'},
      {src: '/blank.jpg', id: '5', link: '#'},
      {src: '/blank.jpg', id: '6', link: '#'}
    ]
  };

  async componentDidMount() {
    const token = config.instagram;
    const max = 6;

    try {
      const endpoint = 'https://api.instagram.com/v1/users/self/media/recent/';
      const response = await fetchJsonp(`${endpoint}?access_token=${token}`);
      const {data} = await response.json();

      if (data) {
        const photos = data.map(photo => ({
          id: photo.id,
          src: photo.images.standard_resolution.url,
          link: photo.link
        }));
        this.setState({photos: photos.slice(0, max)});
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {title, button} = this.props;

    return (
      <section className="instagram">
        <div className="container">
          <h2>{title}</h2>
        </div>
        <div className="instagram__photos">
          <div className="row no-gutters">
            {this.state.photos.map(photo => (
              <div key={photo.id} className="col-4 col-md-2 instagram__photo">
                <a href={photo.link} target="_blank" rel="noopener noreferrer">
                  <img src={photo.src} />
                </a>
              </div>
            ))}
          </div>
          <PrismicLink className="button btn-secondary" link={button.link}>
            {button.label}
          </PrismicLink>
        </div>
      </section>
    );
  }
}

Instagram.propTypes = {
  title: string.isRequired,
  button: shape({
    link: object.isRequired,
    label: string.isRequired
  }).isRequired
};

export default Instagram;
