import React from 'react';
import {array, string} from 'prop-types';
import {withRouteData} from 'react-static';
import Prismic from 'prismic-javascript';
import {RichText} from 'prismic-reactjs';
import linkResolver from '../utils/link-resolver';
import PrismicPreview from '../components/prismic-preview';
import Banner from '../components/banner/banner';
import Intro from '../components/intro/intro';
import ImageAndText from '../components/image-and-text/image-and-text';
import GalleryAndText from '../components/gallery-and-text/gallery-and-text';
import Instagram from '../components/instagram/instagram';
import ColumnedTitle from '../components/columned-title/columned-title';

const Page = props => (
  <PrismicPreview
    publishedContent={props}
    query={{predicates: Prismic.Predicates.at('my.page.uid', props.uid)}}
  >
    {({body}) =>
      body
        ? body.map(slice => {
            if (slice.slice_type === 'banner') {
              return (
                <Banner
                  key={slice.slice_type}
                  title={RichText.asText(slice.primary.title)}
                  image={slice.primary.image.url}
                />
              );
            }

            if (slice.slice_type === 'intro') {
              return (
                <Intro
                  key={slice.slice_type}
                  title={RichText.render(slice.primary.title, linkResolver)}
                  text={RichText.render(slice.primary.text, linkResolver)}
                  button={{
                    label: slice.primary.button_label,
                    link: slice.primary.button_link
                  }}
                />
              );
            }

            if (slice.slice_type === 'image___text') {
              return (
                <ImageAndText
                  key={slice.slice_type}
                  title={RichText.render(slice.primary.title, linkResolver)}
                  text={RichText.render(slice.primary.text, linkResolver)}
                  button={{
                    label: slice.primary.button_label,
                    link: slice.primary.button_link
                  }}
                  image={slice.primary.image.url}
                  imagePosition={slice.primary.image_position}
                />
              );
            }

            if (slice.slice_type === 'gallery___text') {
              return (
                <GalleryAndText
                  key={slice.slice_type}
                  title={RichText.render(slice.primary.title, linkResolver)}
                  text={RichText.render(slice.primary.text, linkResolver)}
                  button={{
                    label: slice.primary.button_label,
                    link: slice.primary.button_link
                  }}
                  galleryItems={slice.items}
                  imagePosition={slice.primary.image_position}
                />
              );
            }

            if (slice.slice_type === 'instagram') {
              return (
                <Instagram
                  title={RichText.asText(slice.primary.title)}
                  button={{
                    label: slice.primary.button_label,
                    link: slice.primary.button_link
                  }}
                />
              );
            }

            if (slice.slice_type === 'columned_title') {
              return (
                <ColumnedTitle
                  title={RichText.asText(slice.primary.title)}
                  text={RichText.render(slice.primary.text, linkResolver)}
                />
              );
            }

            return null;
          })
        : null
    }
  </PrismicPreview>
);

Page.propTypes = {
  body: array.isRequired,
  uid: string
};

Page.defaultProps = {
  uid: ''
};

export default withRouteData(Page);
