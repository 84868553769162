import React from 'react';
import {string, node, shape, array, object} from 'prop-types';
import {RichText} from 'prismic-reactjs';
import PrismicLink from '../prismic-link';
import './gallery-and-text.css';

const GalleryAndText = ({title, text, button, galleryItems, imagePosition}) => {
  const Gallery = () => (
    <div className="row gallery-wrap gallery-and-text__gallery">
      {galleryItems.map(galleryItem => (
        <div className="col-6" key={RichText.asText(galleryItem.title)}>
          <img
            src={galleryItem.image.url}
            alt={galleryItem.image.alt}
            {...galleryItem.image.dimensions}
          />
          <div className="gallery-and-text__gallery__title">
            <h4>{RichText.asText(galleryItem.title)}</h4>
          </div>
        </div>
      ))}
    </div>
  );

  const Text = () => (
    <div className="gallery-and-text__text">
      {title}
      {text}
      <PrismicLink className="button btn-secondary" link={button.link}>
        {button.label}
      </PrismicLink>
    </div>
  );

  return (
    <section
      className={`gallery-and-text gallery-and-text--position-${imagePosition.toLowerCase()}`}
    >
      <div className="container">
        <div className="row">
          <div
            className={`col-md order-md-1 gallery-and-text__col order-${
              imagePosition === 'Right' ? '1' : '2'
            }`}
          >
            {imagePosition === 'Right' ? <Text /> : <Gallery />}
          </div>
          <div
            className={`col-md order-md-2 gallery-and-text__col order-${
              imagePosition === 'Right' ? '2' : '1'
            }`}
          >
            {imagePosition === 'Right' ? <Gallery /> : <Text />}
          </div>
        </div>
      </div>
    </section>
  );
};

GalleryAndText.propTypes = {
  title: string.isRequired,
  text: node.isRequired,
  button: shape({
    link: object.isRequired,
    label: string.isRequired
  }).isRequired,
  galleryItems: array.isRequired,
  imagePosition: string.isRequired
};

export default GalleryAndText;
