import React from 'react';
import {string, node} from 'prop-types';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import './columned-title.css';

const ColumnedTitle = ({title, text}) => (
  <section className="columned-title">
    <div className="container">
      <Breadcrumbs page={title} />
      <h1>{title}</h1>
      <div className="columned-title__text">{text}</div>
    </div>
  </section>
);

ColumnedTitle.propTypes = {
  title: string,
  text: node
};

export default ColumnedTitle;
