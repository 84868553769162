import React from 'react';
import {string} from 'prop-types';
import './banner.css';

const Banner = ({title, image}) => (
  <>
    <div className="banner" style={{backgroundImage: `url(${image})`}}>
      {title && <h1 className="d-none d-md-block">{title}</h1>}
    </div>
    {title && (
      <div className="d-md-none banner__title">
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>
    )}
  </>
);

Banner.propTypes = {
  title: string,
  image: string
};

Banner.defaultProps = {
  title: '',
  image: '/blank.jpg'
};

export default Banner;
