import React from 'react';
import {string, node, shape, object} from 'prop-types';
import PrismicLink from '../prismic-link';
import './intro.css';

const Intro = ({title, text, button}) => (
  <section className="intro">
    <div className="container">
      {title}
      {text}
      <PrismicLink className="button btn-secondary" link={button.link}>
        {button.label}
      </PrismicLink>
    </div>
  </section>
);

Intro.propTypes = {
  title: string.isRequired,
  text: node.isRequired,
  button: shape({
    link: object.isRequired,
    label: string.isRequired
  }).isRequired
};

export default Intro;
