import React from 'react';
import {string, node, shape, object} from 'prop-types';
import PrismicLink from '../prismic-link';
import './image-and-text.css';

const ImageAndText = ({title, text, button, image, imagePosition}) => {
  const Image = () => (
    <div
      className="image-and-text__image"
      style={{backgroundImage: `url(${image})`}}
    >
      <img src={image} />
    </div>
  );

  const Text = () => (
    <div className="image-and-text__text">
      {title}
      {text}
      <PrismicLink className="button btn-secondary" link={button.link}>
        {button.label}
      </PrismicLink>
    </div>
  );

  return (
    <section
      className={`image-and-text image-and-text--position-${imagePosition.toLowerCase()}`}
    >
      <div className="container">
        <div className="row">
          <div
            className={`col-md order-md-1 image-and-text__col order-${
              imagePosition === 'Right' ? '1' : '2'
            }`}
          >
            {imagePosition === 'Right' ? <Text /> : <Image />}
          </div>
          <div
            className={`col-md order-md-2 image-and-text__col order-${
              imagePosition === 'Right' ? '2' : '1'
            }`}
          >
            {imagePosition === 'Right' ? <Image /> : <Text />}
          </div>
        </div>
      </div>
    </section>
  );
};

ImageAndText.propTypes = {
  title: string.isRequired,
  text: node.isRequired,
  button: shape({
    link: object.isRequired,
    label: string.isRequired
  }).isRequired,
  image: string.isRequired,
  imagePosition: string.isRequired
};

export default ImageAndText;
